<template>
  <div class="home">
    <Head recruit="true" hId="0"></Head>
    <div class="main">
      <div class="banner">
        <el-carousel trigger="click">
          <el-carousel-item v-for="(item, index) in bannerIndex" :key="index">
            <img height="320" :src="item.imgUrl" @click="jumpPage(index)" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content">
        <div class="activity fs" v-if="activity.length>0">
          <div>
            <a  :href="'javascript:void(0);'" @click="jumpPage2(0)"><img height="217" v-if="activity[0]" :src="activity[0].imgUrl" alt="" /></a>
          </div>
          <div>
            <a :href="'javascript:void(0);'" @click="jumpPage2(1)"><img height="217" v-if="activity[1]" :src="activity[1].imgUrl" alt="" /></a>
          </div>
          <div>
            <a  :href="'javascript:void(0);'" @click="jumpPage2(2)"><img height="217" v-if="activity[2]" :src="activity[2].imgUrl" alt="" /></a>
          </div>
        </div>
        <div class="tabStyle">
          <el-tabs @tab-click="goto" v-model="recruit" >
            <el-tab-pane name="rc">
              <span slot="label"
                ><img src="@/assets/img/recruit/icon-dz.png" alt="" />
                人才推荐</span
              >
              <div class="cont">
                <Resume :dataList="recommendList"/>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"
                ><img
                  src="@/assets/img/recruit/icon-ya.png"
                  alt=""
                />
                发布职位</span
              >
              <div class="cont">
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="more" @click="showMore()">
          <p>查看更多</p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import {getBanner, recommend} from '@/api/zp'
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Resume from '@/components/Resume'
// import html2canvas from 'html2canvas'
// import { jsPDF } from 'jspdf'
export default {
  name: "Recruit",
  data(){
    return {
      bannerIndex: [],
      activity: [],
      recommendList: [],
      recruit: 'rc'
    }
  },
  components: {
    Head,
    Footer,
    Resume
  },
  created () {
    getBanner({position: 30, type: 1}).then(res => {
      this.bannerIndex = res.data
    })
    getBanner({position: 31, type: 1}).then(res => {
      this.activity = res.data
    })
    this.getrecommend()
  },
  methods: {
    // 去发布
    goto(){
      this.recruit = 'rc'
      this.$router.push('/recruitCenter/releaseed')
    },
    // 跳转
    jumpPage(i) {
      if(this.bannerIndex[i].type == 1){
        console.log(2)
      } else if(this.bannerIndex[i].type == 2){
        window.open(this.bannerIndex[i].jumpUrl)
      } else if(this.bannerIndex[i].type == 3){
        window.open('/newDetail/?id=' + this.bannerIndex[i].jumpUrl)
      } else if(this.bannerIndex[i].type == 4){
        window.open('/indexDetails/?id=' + this.bannerIndex[i].jumpUrl)
      } else if(this.bannerIndex[i].type == 5){
        window.open('/recruit/show?cs=1&id=' + this.bannerIndex[i].jumpUrl)
      }
    },
    // 跳转
    jumpPage2(i) {
      if(this.activity[i].type == 1){
        console.log(2)
      } else if(this.activity[i].type == 2){
        window.open(this.activity[i].jumpUrl)
      } else if(this.activity[i].type == 3){
        window.open('/newDetail/?id=' + this.activity[i].jumpUrl)
      } else if(this.activity[i].type == 4){
        window.open('/indexDetails/?id=' + this.activity[i].jumpUrl)
      } else if(this.activity[i].type == 5){
        window.open('/recruit/show?cs=1&id=' + this.activity[i].jumpUrl)
      }
    },
    // 推荐
    getrecommend() {
      recommend({
        pageSize: 21,
        pageNum: 1
      }).then(res => {
        this.recommendList = res.rows
      })
    },

    // 查看更多
    showMore() {
      this.$router.push({ path: "/recruit/search" });
    }
  }
};
</script>
<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
}
.home .main {
  min-height: calc(100% - 278px);
}
.home .banner{
  height: 320px;
}
.home .banner img {
  width: 100%;
  cursor: pointer;
}
.home .activity {
  margin: 20px 0 40px 0;
  flex-wrap: wrap;
}
.home .activity div {
  width: 386px;
  height: 217px;
}
.home .activity:after{
  content: "";
  width: 386px;
  height: 0px;
}
.home .activity div img {
  width: 100%;
}
.home .tabStyle {
  margin-bottom: 20px;
}
.home .tabStyle /deep/ .el-tabs__nav-scroll {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 45%,
    #ffffff 100%
  );
}
.home .tabStyle /deep/ .el-tabs__item {
  font-size: 22px;
  color: #666;
  padding: 0 100px 0 20px;
  height: 98px;
  line-height: 98px;
}
.home .tabStyle /deep/ .el-tabs__item{
  font-size: 22px;
  color: #666;
}
.home .tabStyle /deep/ .el-tabs__item img{
  position: relative;
  top: 3px;
  width: 24px;
  height: 24px;
}
.cont{
  margin-right: -20px;
}
.home .more {
  margin-bottom: 60px;
}

.home .more p {
  width: 386px;
  height: 50px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #0780E6;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  font-weight: 400;
  color: #0780E6;
  margin: 0 auto;
  cursor: pointer;
}
</style>
